let params = {};

window.location.search
  .substring(1)
  .split("&")
  .forEach(function (item) {
    params[item.split("=")[0]] = item.split("=")[1];
  });

export default params;
