/* eslint-disable react-hooks/exhaustive-deps */

import "./App.css";
import { useEffect, useState } from "react";
import { get, post } from "./utils/fetch";
import params from "./utils";
import { Oval } from "react-loader-spinner";
import moment from "moment";

const pesapalUrl = "https://restaurants.reserveport.com/foodie-api/api/v1";
const BASE_URL = "https://cloud.dial-a-delivery.online/api";

const headers = {
  Accept: "application/json",
  "content-type": "application/json",
};

const _msg = "Please wait, we are processing your payment...";

function App() {
  const [msg, setMsg] = useState(_msg);
  const [retry, setRetry] = useState(false);

  useEffect(() => {
    const { uuid, OrderTrackingId, OrderMerchantReference } = params;

    if (uuid) {
      if (retry) {
        setMsg(_msg);
        setRetry(false);
      }

      getOrderAndPayment(uuid);
    } else {
      if (OrderTrackingId) {
        setMsg("Please wait, we are confirming your payment...");
        pesapalIPN(OrderTrackingId, OrderMerchantReference);
      } else {
        setMsg("Order not found, please try again.");
      }
    }
  }, []);

  const getOrderAndPayment = async (uuid) => {
    const respone = await get(`${BASE_URL}/cart_order/${uuid}`);

    if (respone.complex_uuid) {
      if (respone.payment) {
        const { id } = respone.payment;

        const url = `${BASE_URL}/complexes/${respone.complex_uuid}/get_payment/${id}`;

        const payment = await get(url);

        if (payment) {
          await requestToken(respone, payment);
        } else {
          setMsg("Order payment not found, please try again.");
        }
      }
    } else {
      setMsg("Order not found, please try again.");
    }
  };

  const requestToken = async (order, payment) => {
    logPs({
      name: "Request a Token",
      icon: "clock",
      color: "orange",
    });

    if (payment.credentials) {
      const { username, password } = payment.credentials;

      const data = {
        username,
        password,
      };

      const url = `${pesapalUrl}/auth/login`;

      const { access_token } = await post(url, data, headers);

      if (access_token) {
        logPs({
          name: "Request a Token",
          icon: "check-circle",
          color: "green",
        });

        await submitOrderRequest(access_token, order);
      } else {
        setMsg("Failed to get authorization token, please try again.");
        setRetry(true);

        logPs({
          name: "Request a Token",
          icon: "times-circle",
          color: "red",
          reason: "Failed to get authorization token, please try again.",
        });
      }
    } else {
      setMsg("Your order payment credentials not found, please try again.");
      setRetry(true);

      logPs({
        name: "Request a Token",
        icon: "times-circle",
        color: "red",
        reason: "Your order payment credentials not found, please try again.",
      });
    }
  };

  const submitOrderRequest = async (token, order) => {
    logPs({
      name: "Checkout Charge Request",
      icon: "clock",
      color: "orange",
    });

    if (order) {
      const nameArray = order.customer.name.trim().split(" ");

      const customerFirstName = nameArray[0];
      let customerLastName = nameArray[0];

      if (nameArray.length > 1) {
        if (nameArray[1]) {
          customerLastName = nameArray[1];
        }
      }

      const currentTime = moment().format("HH:mm:ss");
      const trans_id = order.order_uuid + "-" + currentTime.replaceAll(":", "");

      const data = {
        merchant_transaction_id: trans_id,
        amount: order.order_total_price,
        callback_url: `${BASE_URL}/pesapal/ipn`,
        redirect_url: window.location.origin,
        cancellation_url: "https://cloud.dial-a-delivery.online/webview/cancel",
        msisdn: "0" + order.customer.phone.substring(4),
        store_id: "TURNUP",
        currency_code: order.currency,
        country_code: order.country_code,
        channel: 1,
        customer_email: "none@gmail.com",
        customer_first_name: customerFirstName,
        customer_last_name: customerLastName,
      };

      onLog(data, "pesapal", "submitOrderRequest_request");

      const url = `${pesapalUrl}/checkout-charge`;

      headers["Authorization"] = "Bearer " + token;

      const respsone = await post(url, data, headers);

      onLog(respsone, "pesapal", "submitOrderRequest_Response");

      const { redirect_url, error } = respsone;

      if (redirect_url) {
        logPs({
          name: "Checkout Charge Request",
          icon: "check-circle",
          color: "green",
        });

        window.location.href = redirect_url;
      } else {
        if (error) {
          const { message } = error;
          if (message) {
            setMsg(message);
          }
        } else {
          setMsg("Failed to submit your order request, please try again.");
        }

        logPs({
          name: "Checkout Charge Request",
          icon: "times-circle",
          color: "red",
          reason: msg,
        });
        setRetry(true);
      }
    }
  };

  const onRetry = () => {
    if (retry) {
      setMsg(_msg);
      setRetry(false);
    }

    const { uuid } = params;

    getOrderAndPayment(uuid);
  };

  const onCancel = () => {
    window.location.replace(
      "https://cloud.dial-a-delivery.online/webview/cancel"
    );
  };

  const onLog = async (payload, _function, operation) => {
    const { uuid } = params;

    await fetch(`${BASE_URL}/logger`, {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        payload,
        _function,
        operation,
        key: uuid,
      }),
    });
  };

  const pesapalIPN = async (trackingId, merchantReference, limit = 10) => {
    const urlPo = `${BASE_URL}/pesapal/paymentStatus`;

    const dataPo = {
      OrderTrackingId: trackingId,
      OrderMerchantReference: merchantReference,
    };

    const { success } = await post(urlPo, dataPo, headers);

    if (success) {
      window.location.replace(
        "https://cloud.dial-a-delivery.online/webview/success"
      );
    } else {
      if (limit > 0) {
        pesapalIPN(trackingId, merchantReference, limit - 1);
      } else {
        setMsg("Failed to get your payment request status, please try again.");
      }
    }
  };

  const logPs = async (state) => {
    await fetch(`${BASE_URL}/payment/state`, {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        order_uuid: params.uuid,
        state,
      }),
    });
  };

  return (
    <div className="App">
      <header className="App-header">
        <Oval color="#47a2b5" height="60" width="60" />

        <p>{msg}</p>

        {retry && (
          <div style={{ color: "#ffff" }}>
            <div
              style={{
                width: "100%",
                padding: 10,
                backgroundColor: "red",
                borderRadius: 20,
              }}
              onClick={onRetry}
            >
              Try Again
            </div>
            <br />
            <div
              style={{
                width: "100%",
                padding: 10,
                backgroundColor: "grey",
                borderRadius: 20,
              }}
              onClick={onCancel}
            >
              Cancel
            </div>
          </div>
        )}
      </header>
    </div>
  );
}

export default App;
